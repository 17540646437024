import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { searchPlayersByName } from '../../services/PlayerService'; // Ensure you add this function to your service
import './PlayerSearch.css'; // Create this file for custom styling
import { Player } from '../../types/Player';

const PlayerSearch: React.FC = () => {
  const [query, setQuery] = useState('');
  const [players, setPlayers] = useState<Player[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const fetchPlayers = async () => {
      if (query.length > 2) {
        try {
          const results = await searchPlayersByName(query);
          setPlayers(results);
          setShowDropdown(true);
        } catch (error) {
          console.error('Error fetching players:', error);
        }
      } else {
        setPlayers([]);
        setShowDropdown(false);
      }
    };

    fetchPlayers();
  }, [query]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return (
    <div className="player-search">
      <input
        type="text"
        placeholder="Buscar jugador..."
        value={query}
        onChange={handleInputChange}
        onFocus={() => setShowDropdown(true)}
      />
      {showDropdown && players.length > 0 && (
        <ul className="dropdown">
          {players.map((player) => (
            <li key={player.id} className="dropdown-item">
              <Link to={`/players/${player.id}`} className="dropdown-link" onClick={() => setShowDropdown(false)}>
                {player.firstName + ' ' + player.lastName} (ID: {player.id})
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PlayerSearch;
