import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createOpenChallenge } from '../../services/OpenChallengeService'; // Service method
import { OpenChallenge } from '../../types/OpenChallenge';
import './OpenChallengeForm.css';
import { useAuth } from '../../context/AuthContext';
import { getPlayerById } from '../../services/PlayerService';

const OpenChallengeForm: React.FC = () => {
  const { playerAuth } = useAuth();
  const [proposedDate, setProposedDate] = useState<string>('');
  const [startTimes, setStartTimes] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [canCreateOpenChallenge, setCanCreateOpenChallenge] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlayerData = async () => {
      // Check for null on playerAuth
      if (!playerAuth || !playerAuth.id) {
        setCanCreateOpenChallenge(false);
        setError('No se pudo obtener el jugador autenticado.');
        return;
      }
      const playerData = await getPlayerById(playerAuth.id);
      console.log(playerData);
      //player should have absoluteOrdinalRanking and should be greater than 0
      if (!playerData || !playerData.absoluteOrdinalRanking || playerData.absoluteOrdinalRanking <= 0) {
        setCanCreateOpenChallenge(false);
        setError('No se pudo obtener el ranking absoluto del jugador autenticado.');
        return;
      }
    };
    fetchPlayerData();
  }, [playerAuth]);

  // Handle time selection
  const handleTimeClick = (time: string) => {
    setStartTimes((prevTimes) => {
      if (prevTimes.includes(time)) {
        return prevTimes.filter((t) => t !== time); // Remove time if already picked
      } else {
        return [...prevTimes, time]; // Add time if not picked
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!proposedDate || startTimes.length === 0) {
      setError('Debes elegir una fecha y al menos un horario.');
      return;
    }

    //Date has to be today or in the future
    const yesterdayAt23hs = new Date();
    yesterdayAt23hs.setDate(yesterdayAt23hs.getDate() - 1);
    yesterdayAt23hs.setHours(23, 0, 0, 0);
    if (new Date(proposedDate) < yesterdayAt23hs) {
      setError('La fecha propuesta debe ser hoy o en el futuro.');
      return;
    }

    // Check for null on playerAuth
    if (!playerAuth || !playerAuth.id) {
      setCanCreateOpenChallenge(false);
      setError('No se pudo obtener el jugador autenticado.');
      return;
    }

    const newOpenChallenge: Partial<OpenChallenge> = {
      playerId: playerAuth.id, // Use the logged-in player's ID
      playerRanking: playerAuth.absoluteOrdinalRanking || 0, // Assuming ranking is fetched from player model
      proposedDate: new Date(proposedDate),
      startTimes,
      rankingType: 'absoluteOrdinalRanking', // Fixed ranking type
      status: 'open',
    };

    try {
      await createOpenChallenge(newOpenChallenge);
      setSuccessMessage('Desafío abierto creado con éxito.');
      setTimeout(() => {
        navigate('/');
      }, 800);
    } catch (error: any) {
        console.log(error)
      setError('Error: ' + error.response.data.message);
      setSuccessMessage(null);
    }
  };

  // Generate time slots from 8:00 AM to 9:00 PM with 1-hour gaps
  const timeSlots = Array.from({ length: 14 }, (_, i) => {
    const hour = i + 8; // Starting at 8 AM
    const minutes = '00';
    return `${hour}:${minutes}`;
  });

  return (
    <div className="form-container">
      <h2 className="form-title">Crear nuevo desafío abierto</h2>
      {error && <p className="form-message form-message-error">{error}</p>}
      {successMessage && <p className="form-message form-message-success">{successMessage}</p>}
      
      <form onSubmit={handleSubmit}>
        {/* Proposed Date */}
        <div className="mb-3">
          <label htmlFor="proposedDate" className="form-label">Fecha propuesta</label>
          <input
            type="date"
            value={proposedDate}
            onChange={(e) => setProposedDate(e.target.value)}
            className="form-input"
          />
        </div>

        {/* Start Times */}
        <div className="mb-3">
          <label className="form-label">Horarios disponibles</label>
          <div className="time-slot-container">
            {timeSlots.map((time) => (
              <button
                key={time}
                type="button"
                className={`time-slot ${startTimes.includes(time) ? 'selected' : ''}`}
                onClick={() => handleTimeClick(time)}
              >
                {time}
              </button>
            ))}
          </div>
        </div>
            
        {/* Submit Button*/}
        {canCreateOpenChallenge ? (
          <button type="submit" className="form-button">Crear desafío abierto</button>
        ) : (
          <p className="form-message form-message-error">No se pudo obtener el jugador autenticado.</p>
        )}
      </form>
    </div>
  );
};

export default OpenChallengeForm;
