import React from 'react';
import './Rankings.css';
import RankingTable from '../../components/RankingTable/RankingTable';

const Rankings: React.FC = () => {
    return (
        <div className="p-4">
            <section className="mb-8">
                <RankingTable rankType="absoluteOrdinalRanking" />
                <RankingTable rankType="age45OrdinalRanking" />
                <RankingTable rankType="age55OrdinalRanking" />
            </section>
        </div>
    );
};

export default Rankings;