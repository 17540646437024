import axios from 'axios';
import { User } from '../types/User';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/users`;

export const getAllUsers = async (): Promise<User[]> => {
  const response = await axios.get<User[]>(`${API_BASE_URL}`);
  return response.data;
};

export const getUserById = async (id: number): Promise<User> => {
  const response = await axios.get<User>(`${API_BASE_URL}/${id}`);
  return response.data;
};

export const createUser = async (user: Partial<User>): Promise<User> => {
  const response = await axios.post<User>(`${API_BASE_URL}`, user);
  return response.data;
};

export const updateUser = async (id: number, user: Partial<User>): Promise<User> => {
  const response = await axios.put<User>(`${API_BASE_URL}/${id}`, user);
  return response.data;
};

export const deleteUser = async (id: number): Promise<void> => {
  await axios.delete(`${API_BASE_URL}/${id}`);
};

export const changePassword = async (id: number, currentPassword: string, newPassword: string): Promise<void> => {
  await axios.put(`${API_BASE_URL}/${id}/password`, { currentPassword, newPassword });
};