import React, { useState } from 'react';
import './AcceptChallengeButton.css';

interface AcceptChallengeButtonProps {
  challengeId: number;
  onAccept: (challengeId: number) => void;
}

const AcceptChallengeButton: React.FC<AcceptChallengeButtonProps> = ({ challengeId, onAccept }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleAcceptChallenge = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDisabled(true);
    onAccept(challengeId);
  };

  return (
    <button
      onClick={handleAcceptChallenge}
      className="accept-challenge-button"
      disabled={isDisabled}
    >
      Aceptar Desafío
    </button>
  );
};

export default AcceptChallengeButton;
