import axios from 'axios';
import { RankingPermutation } from '../types/RankingPermution';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/ranking-permutations`;

// Fetch all ranking permutations
export const getAllRankingPermutations = async (): Promise<RankingPermutation[]> => {
  const response = await axios.get<RankingPermutation[]>(`${API_BASE_URL}`);
  return response.data;
};

// Fetch ranking permutations by player ID
export const getRankingPermutationsByPlayerId = async (
  playerId: number,
  from?: Date,
  to?: Date
): Promise<RankingPermutation[]> => {
  const queryParams = new URLSearchParams();
  if (from) queryParams.append('from', from.toISOString());
  if (to) queryParams.append('to', to.toISOString());

  const response = await axios.get<RankingPermutation[]>(`${API_BASE_URL}/player/${playerId}?${queryParams.toString()}`);
  return response.data;
};

// Fetch ranking permutations by match ID
export const getRankingPermutationsByMatchId = async (matchId: number): Promise<RankingPermutation[]> => {
  const response = await axios.get<RankingPermutation[]>(`${API_BASE_URL}/match/${matchId}`);
  return response.data;
};

// Create a new ranking permutation
export const createRankingPermutation = async (rankingPermutation: Partial<RankingPermutation>): Promise<RankingPermutation> => {
  const response = await axios.post<RankingPermutation>(`${API_BASE_URL}`, rankingPermutation);
  return response.data;
};

// Update a ranking permutation
export const updateRankingPermutation = async (
  id: number,
  rankingPermutation: Partial<RankingPermutation>
): Promise<RankingPermutation> => {
  const response = await axios.put<RankingPermutation>(`${API_BASE_URL}/${id}`, rankingPermutation);
  return response.data;
};

// Delete a ranking permutation by ID
export const deleteRankingPermutation = async (id: number): Promise<void> => {
  await axios.delete(`${API_BASE_URL}/${id}`);
};
