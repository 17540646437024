import React, { useEffect, useState } from 'react';
import './AdminMatchView.css';
import { MatchView } from '../../types/MatchView';
import { getPendingConfirmationMatches, confirmMatch } from '../../services/MatchService';

const AdminMatchView: React.FC = () => {
    const [matches, setMatches] = useState<MatchView[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [confirming, setConfirming] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [matchesPerPage] = useState<number>(10);
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error message state

    useEffect(() => {
        const fetchMatches = async () => {
            setLoading(true);
            setErrorMessage(null); // Clear previous errors
            try {
                const data = await getPendingConfirmationMatches();
                setMatches(data);
            } catch (error) {
                console.error("Error fetching matches:", error);
                setErrorMessage("Error al cargar los partidos. Por favor, inténtelo de nuevo."); // Set error message
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, []);

    const handleConfirmMatch = async (matchId: number) => {
        setConfirming(matchId);
        setErrorMessage(null);
        try {
            const updatedMatch = await confirmMatch(matchId);
            setMatches((prevMatches) =>
                prevMatches.map((match) =>
                    match.id === updatedMatch.id ? updatedMatch : match
                )
            );
        } catch (error) {
            console.error("Error confirming match:", error);
            setErrorMessage("Error al confirmar el partido. Por favor, inténtelo de nuevo."); // Set error message
        } finally {
            setConfirming(null);
            window.location.reload();
        }
    };

    if (loading) return <p>Cargando resultados de los partidos...</p>;

    // Calculate current matches to display
    const indexOfLastMatch = currentPage * matchesPerPage;
    const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
    const currentMatches = matches.slice(indexOfFirstMatch, indexOfLastMatch);

    // Calculate total pages
    const totalPages = Math.ceil(matches.length / matchesPerPage);

    // Pagination controls
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="admin-home">
            <section>
                <h1 className="section-title">Administrador</h1>
                
                {/* Render error message if exists */}
                {errorMessage && <div className="error-message">{errorMessage}</div>}

                <div className="match-results">
                    <h1 className="section-title">PARTIDOS A CONFIRMAR</h1>
                    <table className="table-auto">
                        <thead>
                            <tr>
                                <th>ID Partido</th>
                                <th>Fecha</th>
                                <th>Jugador 1</th>
                                <th>Jugador 2</th>
                                <th>Ganador</th>
                                <th>Resultado</th>
                                <th>Detalles del Desafío</th>
                                <th>Esta confirmado?</th>
                                <th>Confirmación</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentMatches.map((match) => (
                                <tr key={match.id}>
                                    <td>{match.id}</td>
                                    <td>{new Date(match.matchDate).toLocaleDateString()}</td>
                                    <td>{match.player1?.firstName} {match.player1?.lastName}</td>
                                    <td>{match.player2?.firstName} {match.player2?.lastName}</td>
                                    <td>{(match.winner?.firstName + " " + match.winner?.lastName) || "N/A"}</td>
                                    <td>
                                        {match.result ? (
                                            match.result.sets.map(set => (
                                                <div key={set.set}>
                                                    {`${set.score}`}
                                                    {set.tiebreak && " (Tiebreak)"}
                                                </div>
                                            ))
                                        ) : "Sin resultado"}
                                    </td>
                                    <td>
                                        <a href={`/challenges/${match.challengeId}`} className="btn-view-details">
                                            Ver Detalles
                                        </a>
                                    </td>
                                    <td>{match.isConfirmed ? "Sí" : "No"}</td>
                                    <td>
                                        {match.isConfirmed ? (
                                            <span>-</span>
                                        ) : (
                                            <button
                                                className="btn-confirm"
                                                onClick={() => handleConfirmMatch(match.id)}
                                                disabled={confirming === match.id}
                                            >
                                                {confirming === match.id ? "Confirmando..." : "Confirmar"}
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="pagination">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Anterior
                        </button>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Siguiente
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AdminMatchView;
