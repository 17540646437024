import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center p-4">
      <h1 className="text-4xl font-bold text-red-600">404</h1>
      <p className="text-xl mt-4">Página no encontrada</p>
      <p className="mt-2">Lo sentimos, la página que estás buscando no existe.</p>
      <Link to="/" className="mt-4 text-blue-500 hover:underline">
        Volver al inicio
      </Link>
    </div>
  );
};

export default NotFound;
