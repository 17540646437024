import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChallengeList from '../../components/ChallengeList/ChallengeList';
import RankingTable from '../../components/RankingTable/RankingTable';
import MatchList from '../../components/MatchList/MatchList';
import './Home.css';
import PlayerSearch from '../../components/PlayerSearch/PlayerSearch';
import { useAuth } from '../../context/AuthContext';
import { allRankTypes, RankType } from "../../types/RankType";
import DynamicRankingTable from '../../components/DynamicRankingTable/DynamicRankingTable';
import { getDates } from '../../helpers/timeHelpers';
import OpenChallengeList from '../../components/OpenChallengeList/OpenChallengeList';

const Home: React.FC = () => {
    const { nextYear, tomorrow, today, lastYear, yesterday, today2am, tomorrow2, threeDaysAgo } = getDates();
    const { playerAuth } = useAuth();
    const navigate = useNavigate();
    //const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const [shouldRenderPendingChallenges, setShouldRenderPendingChallenges] = useState<boolean>(true);
    const [shouldRenderLoadPendingChallenges, setShouldRenderLoadPendingChallenges] = useState<boolean>(true);
    const [shouldRenderMyNextChallenges, setShouldRenderMyNextChallenges] = useState<boolean>(true);
    const [shouldRenderGeneralNextChallenges, setShouldRenderGeneralNextChallenges] = useState<boolean>(true);
    const [playerRankTypes, setPlayerRankTypes] = useState<RankType[]>([]);
    const [shouldRenderOpenChallenges, setShouldRenderOpenChallenges] = useState<boolean>(true);

    /* useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); */

    useEffect(() => {
        let playerRankings: RankType[] = [];
        allRankTypes.forEach(rankType => {
            if (playerAuth !== null && playerAuth !== undefined && playerAuth[rankType] !== null && playerAuth[rankType] !== undefined) {
                playerRankings.push(rankType);
            }
        });
        setPlayerRankTypes(playerRankings);
    }, [playerAuth]);

    const handleCreateChallenge = () => {
        navigate('/create-challenge');
    };

    const handleCreateOpenChallenge = () => {
        navigate('/create-open-challenge');
    };

    return (
        <div className="p-4">
            <div className="logo-container">
                <img src="/logo1.png" alt="Whats Icon" />
            </div>

            <button className="create-challenge-button" onClick={handleCreateOpenChallenge}>
                CREAR DESAFÍO ABIERTO
            </button>
            {/* Desafios Pendiente de cargar */}
            {playerAuth && shouldRenderLoadPendingChallenges && (
                <>
                    <h1 className="section-title">Pendientes de Carga</h1>
                    <div className="player-challenges">
                        <ChallengeList
                            status={"accepted"}
                            from={lastYear}
                            to={today}
                            playerId={playerAuth.id}
                            setShouldRender={setShouldRenderLoadPendingChallenges}
                            orderDirection={'asc'}
                        />
                    </div>
                </>
            )}

             {/* Proximos desafios ABIERTOS del usuario */}
             {playerAuth && shouldRenderOpenChallenges && (
                <section>
                    <h1 className="section-title">Desafíos Abiertos en mi rango</h1>
                    <OpenChallengeList
                        playerId={playerAuth.id}
                        setShouldRender={setShouldRenderOpenChallenges}
                    />
                </section>
            )}

            {/* Desafios pendientes de respuesta */}
            {playerAuth && playerAuth.id && shouldRenderPendingChallenges && (
                <section>
                    <h1 className="section-title">Pendientes de respuesta</h1>
                    <ChallengeList
                        status="pending"
                        from={yesterday}
                        to={nextYear}
                        playerId={playerAuth.id}
                        setShouldRender={setShouldRenderPendingChallenges}
                        orderDirection={'asc'}
                    />
                </section>
            )}

            {/* Proximos desafios del usuario */}
            {playerAuth && shouldRenderMyNextChallenges && (
                <section>
                    <h1 className="section-title">Mis Próximos desafíos</h1>
                    <ChallengeList
                        status="accepted"
                        from={today}
                        to={nextYear}
                        playerId={playerAuth.id}
                        setShouldRender={setShouldRenderMyNextChallenges}
                        orderDirection={'asc'}
                    />
                </section>
            )}

            {/* Rankings if a player is NOT logged in */}
            {/* {!playerAuth && (
                <>
                    <section>
                        <h1 className="section-title">Rankings</h1>
                        {allRankTypes.map(rankType => (
                            <RankingTable key={rankType} rankType={rankType} />
                        ))}
                    </section>
                </>
            )} */}

            {/* Rankings if a player is logged in */}
            {playerAuth && (
                <>
                    <section className="mb-8">
                        <h1 className="section-title">Rankings</h1>
                        {playerRankTypes.map(rankType => {
                            if (playerAuth && playerAuth[rankType] !== null && playerAuth[rankType] !== undefined) {
                                return <RankingTable key={rankType} rankType={rankType} />
                            }
                            return null;
                        })}
                    </section>
                </>
            )}


            {/* Ranking de nivel */}
            {playerAuth && (
                <section>
                    <h1 className="section-title">En tu rango de Nivel ELO</h1>
                    <DynamicRankingTable />
                </section>
            )}

            {/* Desafios del dia generales */}
            {shouldRenderGeneralNextChallenges && (
                <section>
                    <h1 className="section-title">Desafios del día</h1>
                    <ChallengeList
                        status="accepted"
                        from={today2am}
                        to={tomorrow2}
                        setShouldRender={setShouldRenderGeneralNextChallenges}
                        orderDirection='asc' />
                </section>
            )}

            <section>
                <h1 className="section-title">Buscador</h1>
                <PlayerSearch />
            </section>
            <button className="create-challenge-button" onClick={handleCreateChallenge}>
                Desafiar Jugador
            </button>

            {/* Ultimos partidos jugados */}
            <section>
                <h1 className="section-title">Últimos resultados</h1>
                <MatchList from={threeDaysAgo} to={tomorrow} />
            </section>


        </div>
    );
};

export default Home;
