import axios from 'axios';
import { User } from '../types/User';

const AUTH_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/login`;

export const loginUser = async (email: string, password: string): Promise<{ user: User; token: string }> => {
  try {
    const response = await axios.post<{ user: User; token: string }>(AUTH_API_BASE_URL, {
      email,
      password
    });
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error('Login failed:', error);
    throw new Error('Login failed');
  }
};
