import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Player } from '../../../types/Player';
import { useAuth } from '../../../context/AuthContext';
import './CreateChallengeButton.css';

interface CreateChallengeButtonProps {
  player: Player | null;
  isAuthenticated: boolean;
}

const CreateChallengeButton: React.FC<CreateChallengeButtonProps> = ({ player, isAuthenticated }) => {
  const navigate = useNavigate();
  const { playerAuth } = useAuth();

  const handleCreateChallenge = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (player && playerAuth !== null && player.id !== playerAuth.id) {
      navigate(`/create-challenge?challengedId=${player.id}`);
    } else {
      navigate('/create-challenge');
    }
  };

  return (
    isAuthenticated ? (
      <button className="create-challenge-button" onClick={handleCreateChallenge}>
        Desafiar a {player ? player.firstName : ''}
      </button>
    ) : null
  );
};

export default CreateChallengeButton;
