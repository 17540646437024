import { RankType } from "../types/RankType";

const rankTypeTranslations: Record<RankType, string> = {
    absoluteOrdinalRanking: 'Ranking Absoluto',
    age45OrdinalRanking: 'Ranking +45',
    age55OrdinalRanking: 'Ranking +55',
    dynamicLevel: 'Nivel Dinámico',
  };

  const rankTypeTranslationsRaw: Record<RankType, string> = {
    absoluteOrdinalRanking: 'Absoluto',
    age45OrdinalRanking: '+45',
    age55OrdinalRanking: '+55',
    dynamicLevel: 'Dinámico',
  };
  
  export const translateRankingType = (rankType: RankType): string => {
    return rankTypeTranslations[rankType];
  };

  export const translateRankingTypeRaw = (rankType: RankType): string => {
    return rankTypeTranslationsRaw[rankType];
  };