import { ChallengeStatus } from "../types/Challenge";

const statusTranslations: Record<ChallengeStatus, string> = {
  pending: 'Pendiente',
  accepted: 'Aceptado',
  rejected: 'Rechazado',
  canceled: 'Cancelado',
  played: 'Jugado',
};

export const translateChallengeStatus = (status: ChallengeStatus): string => {
  return statusTranslations[status];
};