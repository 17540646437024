import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { RankType } from '../../types/RankType';
import { createChallenge } from '../../services/ChallengeService';
import { Challenge } from '../../types/Challenge';
import { useAuth } from '../../context/AuthContext';
import { searchPlayersByName } from '../../services/PlayerService';
import './AdminChallengeForm.css';
import { translateRankingType } from '../../helpers/translateRankingType';

const AdminChallengeForm: React.FC = () => {
  const navigate = useNavigate();
  const { userAuth } = useAuth();
  const [challengerId, setChallengerId] = useState<number | null>(null);
  const [challengedId, setChallengedId] = useState<number | null>(null);
  const [proposedDate, setProposedDate] = useState<any>(new Date());
  const [rankingType, setRankingType] = useState<RankType>('absoluteOrdinalRanking');
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [challengerQuery, setChallengerQuery] = useState<string>('');
  const [challengedQuery, setChallengedQuery] = useState<string>('');
  const [players, setPlayers] = useState<{ id: number; name: string }[]>([]);
  const [showChallengerDropdown, setShowChallengerDropdown] = useState<boolean>(false);
  const [showChallengedDropdown, setShowChallengedDropdown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userAuth?.role !== 'admin') {
      setError('Acceso denegado. Solo los administradores pueden crear desafíos.');
    }
    setError(null);

    // Set proposed date to 2 hours from now
    const newDate = new Date();
    newDate.setHours(newDate.getHours() + 2);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    setProposedDate(newDate);
  }, [userAuth]);

  const handlePlayerSelect = (playerId: number, playerName: string, type: 'challenger' | 'challenged') => {
    if (type === 'challenger') {
      setChallengerId(playerId);
      setChallengerQuery(`${playerName} (ID ${playerId})`);
      setShowChallengerDropdown(false); // Hide challenger dropdown
    } else {
      setChallengedId(playerId);
      setChallengedQuery(`${playerName} (ID ${playerId})`);
      setShowChallengedDropdown(false); // Hide challenged dropdown
    }
  };

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>, type: 'challenger' | 'challenged') => {
    const query = e.target.value;

    if (type === 'challenger') {
      setChallengerQuery(query);
      setShowChallengerDropdown(true); // Show challenger dropdown
      setShowChallengedDropdown(false); // Hide challenged dropdown
    } else {
      setChallengedQuery(query);
      setShowChallengerDropdown(false); // Hide challenger dropdown
      setShowChallengedDropdown(true); // Show challenged dropdown
    }

    if (query.length > 2) {
      try {
        const results = await searchPlayersByName(query);
        setPlayers(results.map(player => ({ id: player.id, name: `${player.firstName} ${player.lastName}` })));
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    } else {
      setPlayers([]);
      setShowChallengerDropdown(false);
      setShowChallengedDropdown(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!userAuth) {
      setError('No hay ningún usuario conectado.');
      return;
    }

    if (!challengerId || !challengedId) {
      setError('Se requieren tanto el jugador desafiante como el jugador retado.');
      return;
    }

    if (challengerId === challengedId) {
      setError('El jugador desafiante y el jugador retado no pueden ser el mismo.');
      return;
    }

    if (proposedDate === null) {
      setError('Fecha y hora propuesta es invalida.');
      return;
    }

    const newChallenge: Partial<Challenge> = {
      challengerId,
      challengedId,
      proposedDate: new Date(proposedDate),
      rankingType,
      status: 'pending',
      isOrdinalRanked: true,
    };

    try {
      setLoading(true);
      const createdChallenge = await createChallenge(newChallenge);
      setSuccessMessage('Desafío creado con éxito.');
      setError(null);

      setTimeout(() => {
        navigate('/challenges/' + createdChallenge.id);
      }, 1000);
    } catch (error: any) {
      console.log(error)
      setError(error.response.data.error);
      setSuccessMessage(null);
    } finally {
      setLoading(false);
    }
  };

  if (!userAuth || userAuth.role !== 'admin') {
    return <p className="text-red-500">Acceso denegado. Solo los administradores pueden crear desafíos.</p>;
  }

  return (
    <div className="p-8 max-w-lg mx-auto bg-white border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold mb-6 text-gray-800">Crear Nuevo Desafío (Admin)</h2>
      {error && <p className="text-red-500 mb-4 text-lg">{error}</p>}
      {successMessage && <p className="text-green-500 mb-4 text-lg">{successMessage}</p>}
      <form onSubmit={handleSubmit}>
        {/* Challenger search input */}
        <div className="mb-6">
          <label htmlFor="challengerId" className="block text-sm font-medium text-gray-700 mb-2">
            Jugador Desafiante
          </label>
          <div className="player-challenge-search">
            <input
              type="text"
              placeholder="Buscar desafiante..."
              value={challengerQuery}
              onChange={(e) => handleSearchChange(e, 'challenger')}
              className="player-challenge-search-input"
              onFocus={() => setShowChallengerDropdown(true)}
            />
            {showChallengerDropdown && players.length > 0 && (
              <ul className="search-challenge-dropdown">
                {players.map((player) => (
                  <li key={player.id} className="dropdown-item">
                    <button
                      type="button"
                      onClick={() => handlePlayerSelect(player.id, player.name, 'challenger')}
                      className="w-full text-left p-2 hover:bg-gray-100"
                    >
                      {player.name} (ID: {player.id})
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Challenged search input */}
        <div className="mb-6">
          <label htmlFor="challengedId" className="block text-sm font-medium text-gray-700 mb-2">
            Jugador Retado
          </label>
          <div className="player-challenge-search">
            <input
              type="text"
              placeholder="Buscar jugador retado..."
              value={challengedQuery}
              onChange={(e) => handleSearchChange(e, 'challenged')}
              className="player-challenge-search-input"
              onFocus={() => setShowChallengedDropdown(true)}
            />
            {showChallengedDropdown && players.length > 0 && (
              <ul className="search-challenge-dropdown">
                {players.map((player) => (
                  <li key={player.id} className="dropdown-item">
                    <button
                      type="button"
                      onClick={() => handlePlayerSelect(player.id, player.name, 'challenged')}
                      className="w-full text-left p-2 hover:bg-gray-100"
                    >
                      {player.name} (ID: {player.id})
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Proposed date input */}
        <div className="mb-6 date-picker-container">
          <label htmlFor="proposedDate" className="block text-sm font-medium text-gray-700 mb-2">
            Fecha y Hora Propuesta
          </label>
          <Datetime
            value={proposedDate}
            onChange={(date) => setProposedDate(date)}
            dateFormat="YYYY-MM-DD"
            timeFormat="HH:mm"
            inputProps={{ placeholder: 'Seleccione la fecha y hora' }}
            className="w-full rounded-lg border border-gray-300 shadow-sm p-3 text-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>

        {/* Ranking type select */}
        <div className="mb-6">
          <label htmlFor="rankingType" className="block text-sm font-medium text-gray-700 mb-2">
            Tipo de Ranking
          </label>
          <select
            id="rankingType"
            value={rankingType}
            onChange={(e) => setRankingType(e.target.value as RankType)}
            className="w-full rounded-lg border border-gray-300 shadow-sm p-3 text-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          >
            <option value="absoluteOrdinalRanking">{translateRankingType('absoluteOrdinalRanking')}</option>
            <option value="age45OrdinalRanking">{translateRankingType('age45OrdinalRanking')}</option>
            <option value="age55OrdinalRanking">{translateRankingType('age55OrdinalRanking')}</option>
          </select>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:bg-blue-400 admin-button"
          disabled={loading}
        >
          {loading ? 'Creando desafío...' : 'Crear Desafío'}
        </button>
      </form>
    </div>
  );
};

export default AdminChallengeForm;
