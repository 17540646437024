export const isDateBeforeToday = (date: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
}

export const isDateBeforeOrEqualtoToday = (date: Date): boolean => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return date <= today;
}

export const getDates = () => {
    const todayDate = new Date();
    const today = todayDate.toISOString().split('T')[0];
    const tonextYear = new Date(todayDate);
    tonextYear.setDate(todayDate.getDate() + 365);
    const nextYear = tonextYear.toISOString().split('T')[0];

    const toTomorrow = new Date(todayDate);
    toTomorrow.setDate(todayDate.getDate() + 1);
    const tomorrow = toTomorrow.toISOString().split('T')[0];

    const fromLastYear = new Date(todayDate);
    fromLastYear.setDate(todayDate.getDate() - 365);
    const lastYear = fromLastYear.toISOString().split('T')[0];

    const fromYesterday = new Date(todayDate);
    fromYesterday.setDate(todayDate.getDate() - 1);
    const yesterday = fromYesterday.toISOString().split('T')[0];

    const fromLastWeek = new Date(todayDate);
    fromLastWeek.setDate(todayDate.getDate() - 7);
    const lastWeek = fromLastWeek.toISOString().split('T')[0];

    //Next week
    const fromNextWeek = new Date(todayDate);
    fromNextWeek.setDate(todayDate.getDate() + 7);
    const nextWeek = fromNextWeek.toISOString().split('T')[0];

    //Day from yesterday 22.00 to tomorrow at 2.am
    const fromYesterday22 = new Date(todayDate);
    fromYesterday22.setDate(todayDate.getDate() - 1);
    fromYesterday22.setHours(22, 0, 0, 0);
    const yesterday22 = fromYesterday22.toISOString();

    //Tomorrow at 2.am
    const toTomorrow2 = new Date(todayDate);
    toTomorrow2.setDate(todayDate.getDate() + 1);
    toTomorrow2.setHours(1, 59, 59, 999);
    const tomorrow2 = toTomorrow2.toISOString();

    //Today at 23:59...
    const totoday2359 = new Date();
    totoday2359.setHours(23, 59, 59, 999);
    const today2359 = totoday2359.toISOString();

    //Today 2am
    const toToday2am = new Date();
    toToday2am.setHours(2, 0, 0, 0);
    const today2am = toToday2am.toISOString();

    // Three days ago
    const fromThreeDaysAgo = new Date(todayDate);
    fromThreeDaysAgo.setDate(todayDate.getDate() - 3);
    const threeDaysAgo = fromThreeDaysAgo.toISOString().split('T')[0];

    return { nextYear, tomorrow, today, lastYear, yesterday, lastWeek, nextWeek, yesterday22, tomorrow2, today2359, today2am, threeDaysAgo };
};


/**
 * Formats a UTC date string to a more readable format.
 * @param date - The date to format, expected in ISO string or Date object.
 * @returns The formatted date string.
 */
export function formatDate(date: string | Date): string {
    // Create a Date object from the input
    const dateObject = new Date(date);

    // Check if the date is valid
    if (isNaN(dateObject.getTime())) {
        return 'Fecha no válida'; // Return a placeholder for invalid dates
    }

    // Format date and time to the desired format
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };

    return dateObject.toLocaleString('es-ES', options).replace(',', '');
}

//Add format date without time
export function formatDateWithoutTime(date: Date): string {
    return new Date(date).toLocaleDateString('es-ES', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });
}