import React, { useEffect, useState } from 'react';
import { acceptOpenChallenge, closeOpenChallenge, getOpenChallenges } from '../../services/OpenChallengeService';
import { OpenChallenge } from '../../types/OpenChallenge';

import { translateRankingType } from '../../helpers/translateRankingType';
import { useAuth } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import './OpenChallengeList.css';
import { formatDateWithoutTime } from '../../helpers/timeHelpers';

interface OpenChallengeListProps {
    playerId: number;
    setShouldRender: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpenChallengeList: React.FC<OpenChallengeListProps> = ({ playerId, setShouldRender }) => {
    const [openChallenges, setOpenChallenges] = useState<OpenChallenge[]>([]);
    const [selectedTimeSlots, setSelectedTimeSlots] = useState<{ [key: number]: string }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const { playerAuth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOpenChallenges = async () => {
            try {
                const challenges = await getOpenChallenges(playerId, 'absoluteOrdinalRanking');
                setOpenChallenges(challenges);

                if (challenges.length === 0) {
                    setShouldRender(false);
                }
            } catch (error) {
                setError('Error al obtener los desafíos abiertos');
                console.error('Error fetching open challenges:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOpenChallenges();
    }, [playerId, setShouldRender]);

    const handleSelectTimeSlot = (challengeId: number, timeSlot: string) => {
        setSelectedTimeSlots(prev => ({ ...prev, [challengeId]: timeSlot }));
    };

    const handleAcceptChallenge = async (challengeId: number) => {
        const selectedTimeSlot = selectedTimeSlots[challengeId];
        if (!selectedTimeSlot) {
            alert('Por favor, selecciona un horario.');
            return;
        }

        const confirmChallengePlayer = window.confirm('¿Seguro que deseas desafiar a este jugador?');
        if (!confirmChallengePlayer) return;
        setIsDisabled(true);

        try {
            const newChallenge = await acceptOpenChallenge(challengeId, selectedTimeSlot, playerId);
            alert('Desafío enviado con éxito.');
            setOpenChallenges(openChallenges.filter(challenge => challenge.id !== challengeId));
            console.log('New challenge:', newChallenge);
            // Redirect to the new challenge page
            navigate(`/challenges/${newChallenge.challengeId}`);
        } catch (error: any) {
            setError('Error al aceptar el desafío. ' + error.response.data.error);
            console.error(`Error accepting challenge ${challengeId}:`, error);
        } finally {
            setIsDisabled(false);
        }
    };

    const handleCancelChallenge = async (challengeId: number) => {
        try {
            const confirmCancelOpenChallenge = window.confirm('¿Seguro que deseas cancelar este desafío abierto?');
            if (!confirmCancelOpenChallenge) return;
            setIsDisabled(true);

            await closeOpenChallenge(challengeId, playerId);
            alert('El desafío abierto ha sido cancelado con éxito.');
            setOpenChallenges(openChallenges.filter(challenge => challenge.id !== challengeId));
        } catch (error) {
            console.error(`Error accepting challenge ${challengeId}:`, error);
        } finally {
            setIsDisabled(false);
        };
    }

    if (loading) return <p>Cargando...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="open-challenge-list">
            {openChallenges.length === 0 ? (
                <p>No hay desafíos abiertos disponibles.</p>
            ) : (
                <>
                    {/* Show error if exists */}
                    {error && <p className="error-message">{error}</p>}
                    {openChallenges.map(openChallenge => (
                        <div key={openChallenge.id} className="challenge-card">

                            <p className='proposed-by'>DESAFÍO ABIERTO DE
                                <Link to={`/players/${openChallenge.player.id}`} className='open-challenger'>
                                    <strong>{openChallenge.player.firstName + ' ' + openChallenge.player.lastName}</strong>
                                </Link>
                            </p>
                            <p>Ranking: <strong>{openChallenge.playerRanking}</strong></p>
                            <p>Nivel: <strong>{openChallenge.player.dynamicLevel.toFixed(2)}</strong></p>
                            <div className='challenge-info-container icon-small mt-4'>
                                <img src="/icons/podium.png" alt="Whats Icon" />
                                <p><strong>{translateRankingType(openChallenge.rankingType)}</strong></p>
                            </div>
                            <div className='challenge-info-container icon-small'>
                                <img src="/icons/calendar.png" alt="Whats Icon" />
                                <p><strong>{formatDateWithoutTime(openChallenge.proposedDate)}</strong></p>
                            </div>
                            <div className="timeslot-container">
                                {/* Show only dates starting in the next hour from now */}
                                
                                {openChallenge.startTimes.map((timeSlot: string) => (
                                    <div
                                        key={timeSlot}
                                        className={`timeslot-box ${selectedTimeSlots[openChallenge.id] === timeSlot
                                            ? 'bg-green-500 text-white'
                                            : 'bg-blue-500 text-white'
                                            }`}
                                        onClick={() => handleSelectTimeSlot(openChallenge.id, timeSlot)}
                                    >
                                        {timeSlot}
                                    </div>
                                ))}
                            </div>

                            {/* Show desafiar button if the authenticated player is not the challenged player */}
                            {playerAuth && playerAuth.id !== openChallenge.playerId && (
                                <button
                                    className="mt-4 bg-green-700 hover:bg-green-600 text-white py-2 px-4 open-challenge-button"
                                    onClick={() => handleAcceptChallenge(openChallenge.id)}
                                    disabled={isDisabled}
                                >
                                    {loading ? 'Desafiando jugador...' : (`Desafiar a ${openChallenge.player.firstName} ${openChallenge.player.lastName}`)}
                                </button>
                            )}

                            {/* Show cancel button if the authenticated player is the challenged/er player and status is Accepted */}
                            {playerAuth && playerAuth.id === openChallenge.playerId && (
                                <button
                                    className="mt-4 bg-red-700 hover:bg-red-600 text-white py-2 px-4 open-challenge-button"
                                    onClick={() => handleCancelChallenge(openChallenge.id)}
                                    disabled={isDisabled}
                                >
                                    Cancelar Desafío abierto
                                </button>
                            )}
                        </div>
                    ))
                    }</>
            )}
        </div>
    );
};

export default OpenChallengeList;
