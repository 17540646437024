import axios from 'axios';
import { Player } from '../types/Player';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/players`;

// Fetch all players
export const getAllPlayers = async (): Promise<Player[]> => {
  const response = await axios.get<Player[]>(`${API_BASE_URL}`);
  return response.data;
};

// Fetch a player by ID
export const getPlayerById = async (id: number): Promise<Player> => {
  const response = await axios.get<Player>(`${API_BASE_URL}/${id}`);
  return response.data;
};

// Create a new player
export const createPlayer = async (player: Partial<Player>): Promise<Player> => {
  const response = await axios.post<Player>(`${API_BASE_URL}`, player);
  return response.data;
};

// Update a player's details
export const updatePlayer = async (id: number, player: Partial<Player>): Promise<Player> => {
  const response = await axios.put<Player>(`${API_BASE_URL}/${id}`, player);
  return response.data;
};

// Delete a player by ID
export const deletePlayer = async (id: number): Promise<void> => {
  await axios.delete(`${API_BASE_URL}/${id}`);
};

// Search players by name
export const searchPlayersByName = async (name: string): Promise<Player[]> => {
  const response = await axios.get<Player[]>(`${API_BASE_URL}/search`, {
    params: { name }
  });
  return response.data;
};

// New method to get playerId by userId
export const getPlayerIdByUserId = async (userId: number): Promise<number | null> => {
  const response = await axios.get<{ playerId: number }>(`${API_BASE_URL}/byUserId/${userId}`);
  return response.data.playerId;
};

export const canBeChallenged = async (player1Id: number, player2Id: number): Promise<boolean> => {
  const response = await axios.get<boolean>(`${API_BASE_URL}/canBeChallenged`, {
    params: { player1Id, player2Id }
  });
  return response.data;
}