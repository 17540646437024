import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import './LoadResultButton.css';

interface LoadResultButtonProps {
  isAuthenticated: boolean;
  challengeId: number;
}

const LoadResultButton: React.FC<LoadResultButtonProps> = ({ isAuthenticated, challengeId }) => {
  const navigate = useNavigate();
  const { playerAuth } = useAuth();

  const handleLoadResult = (event: React.MouseEvent) => {
    event.stopPropagation();
    
    if (isAuthenticated && playerAuth !== null) {
      navigate('/load-result?challengeId=' + challengeId);
    } else {
      navigate('/login');
    }
  };

  return (
    isAuthenticated ? (
      <button className="load-result-button" onClick={handleLoadResult}>
        Cargar Resultado
      </button>
    ) : null
  );
};

export default LoadResultButton;
