import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDynamicRankings } from '../../services/RankingService';
import { BaseRankingView } from '../../types/BaseRankingView';
import '../RankingTable/RankingTable.css';
import './DynamicRankingTable.css';
import { useAuth } from '../../context/AuthContext';

const levelsUpAndDownToDisplay = 1;
const maxRowsToDisplay = 8;

const DynamicRankingTable: React.FC = () => {
  const [rankings, setRankings] = useState<BaseRankingView[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { playerAuth } = useAuth();
  const playerDynamicLevel = playerAuth?.dynamicLevel ?? 1;
  
  const minLevel = Math.max(parseFloat(playerDynamicLevel.toString()) - levelsUpAndDownToDisplay, 1);
  const maxLevel = Math.min(parseFloat(playerDynamicLevel.toString()) + levelsUpAndDownToDisplay, 20);

  useEffect(() => {
    const fetchRankings = async () => {
      setLoading(true);
      setError(null);
      if (!playerAuth) {
        setLoading(false);
        return;
      }

      try {
        const response = await getDynamicRankings(minLevel, maxLevel, playerAuth.id);
        setRankings(response.rankings);
      } catch (err) {
        console.error('Error fetching dynamic rankings:', err);
        setError('Error al obtener los rankings dinámicos.');
      } finally {
        setLoading(false);
      }
    };

    fetchRankings();
  }, [minLevel, maxLevel, playerAuth]);

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  // Find the index of the authenticated player
  const playerIndex = rankings.findIndex(ranking => ranking.playerId === playerAuth?.id);

  // Determine the start and end indices to display
  let startIndex = Math.max(playerIndex - Math.floor(maxRowsToDisplay / 2), 0);
  let endIndex = startIndex + maxRowsToDisplay;

  // Adjust end index if it exceeds the rankings length
  if (endIndex > rankings.length) {
    endIndex = rankings.length;
    startIndex = Math.max(endIndex - maxRowsToDisplay, 0);
  }

  const displayRankings = rankings.slice(startIndex, endIndex);

  return (
    <div className="ranking-table-container elo-table">
      <div className="dynamic-ranking-table-wrapper">
        <table className="ranking-table">
          <thead>
            <tr>
              <th className='column-centered'>Nivel (ELO)</th>
              <th className='column-centered'>Jugador</th>
              <th className='column-centered'>Confiabilidad</th>
            </tr>
          </thead>
          <tbody>
            {displayRankings.map((ranking) => (
              <tr
                key={ranking.playerId}
                className={playerAuth && ranking.playerId === playerAuth.id ? 'highlight-row' : ''}
              >
                <td className='elo-level column-centered'>{ranking.dynamicLevel.toFixed(2)}</td>
                <td className='column-centered player-name-containertd'>
                  <div className="ranking-player-name-box"
                    title={ranking.isChallengeable ? `Desafiar a ${ranking.playerName}` : ''}>
                    <Link to={`/players/${ranking.playerId}`} style={{ display: 'contents' }}>
                      <p>{ranking.playerName}</p>
                    </Link>
                  </div>
                </td>
                <td className='column-centered'>{(ranking.rankingReliability * 100).toFixed(0)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DynamicRankingTable;
