// src/context/AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { User } from '../types/User';
import { getPlayerById, getPlayerIdByUserId } from '../services/PlayerService';
import { Player } from '../types/Player';

interface AuthContextType {
  isAuthenticated: boolean;
  userAuth: User | null;
  playerAuth: Player | null;
  isLoading: boolean; // Add this line
  loginAsync: (token: string, user: User) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userAuth, setUserAuth] = useState<User | null>(null);
  const [playerAuth, setPlayerAuth] = useState<Player | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Add this line

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const userData = localStorage.getItem('userAuth');
    const playerData = localStorage.getItem('playerAuth');
    if (token && userData && playerData) {
      setIsAuthenticated(true);
      setUserAuth(JSON.parse(userData));
      setPlayerAuth(JSON.parse(playerData));
    }
    setIsLoading(false);
  }, []);

  const loginAsync = async (token: string, user: User) => {
    setIsLoading(true); // Set loading to true when logging in
    localStorage.setItem('authToken', token);
    const playerId = await getPlayerIdByUserId(user.id);
    if (playerId) {
      const playerAuth = await getPlayerById(playerId);
      localStorage.setItem('playerAuth', JSON.stringify(playerAuth));
      setPlayerAuth(playerAuth);
    }
    localStorage.setItem('userAuth', JSON.stringify(user));
    setIsAuthenticated(true);
    setUserAuth(user);
    setIsLoading(false);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userAuth');
    localStorage.removeItem('playerAuth');
    setIsAuthenticated(false);
    setUserAuth(null);
    setPlayerAuth(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userAuth, playerAuth, isLoading, loginAsync, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
