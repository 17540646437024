import React, { useState } from 'react';
import './RejectChallengeButton.css';

interface RejectChallengeButtonProps {
  challengeId: number;
  onReject: (challengeId: number) => void;
}

const RejectChallengeButton: React.FC<RejectChallengeButtonProps> = ({ challengeId, onReject }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleRejectChallenge = (event: React.MouseEvent) => {
    event.stopPropagation();

    const confirmReject = window.confirm('¿Seguro que deseas rechazar el desafío?');
    if (confirmReject) {
      setIsDisabled(true);
      onReject(challengeId);
    }
  };

  return (
    <button
      onClick={handleRejectChallenge}
      className="reject-challenge-button"
      disabled={isDisabled}
    >
      Rechazar Desafío
    </button>
  );
};

export default RejectChallengeButton;

