import React, { useEffect, useState } from 'react';
import { getRankingPermutationsByPlayerId } from '../../services/RankingPermutationService';
import { useParams } from 'react-router-dom';
import './RankingChart.css';
import { RankingPermutation } from '../../types/RankingPermution';
import { LineChart } from '@mui/x-charts';
import { useMediaQuery } from 'react-responsive';

const RankingChart: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [absoluteDataset, setAbsoluteDataset] = useState<number[]>([]);
  const [absoluteLabels, setAbsoluteLabels] = useState<string[]>([]);
  const [plus45Dataset, setPlus45Dataset] = useState<any[]>([]);
  const [plus45Labels, setPlus45Labels] = useState<string[]>([]);
  const [plus55Dataset, setPlus55Dataset] = useState<any[]>([]);
  const [plus55Labels, setPlus55Labels] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const isValidDate = (dateString: string | Date) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  useEffect(() => {
    const fetchRankingChanges = async () => {
      try {
        if (id) {
          const today = new Date();
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(today.getMonth() - 6);
          // the date should be six months ago or the 13 of October 2024, whichever comes first
          const firstDate = new Date(2024, 9, 1);
          const dateToUse = sixMonthsAgo < firstDate ? firstDate : sixMonthsAgo;

          const changes: RankingPermutation[] = await getRankingPermutationsByPlayerId(parseInt(id), dateToUse, today);
          // Order changes by CreatedAt
          changes.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

          // Filter by ranking type
          const absolutoChanges = changes.filter(change => change.rankingType === 'absoluteOrdinalRanking');
          const plus45Changes = changes.filter(change => change.rankingType === 'age45OrdinalRanking');
          const plus55Changes = changes.filter(change => change.rankingType === 'age55OrdinalRanking');

          // Check if the date is valid before converting to Date objects
          const absolutoChangesDates = absolutoChanges.map(change => isValidDate(change.createdAt) ? new Date(change.createdAt) : new Date());
          const plus45ChangesDates = plus45Changes.map(change => isValidDate(change.createdAt) ? new Date(change.createdAt) : new Date());
          const plus55ChangesDates = plus55Changes.map(change => isValidDate(change.createdAt) ? new Date(change.createdAt) : new Date());

          const absolutoRankings = absolutoChanges.map(change => change.newRank);
          const plus45Rankings = plus45Changes.map(change => change.newRank);
          const plus55Rankings = plus55Changes.map(change => change.newRank);

          let absolutoLabels: string[] = [];
          let absolutoValues: number[] = [];
          let plus45Labels: string[] = [];
          let plus45Values: number[] = [];
          let plus55Labels: string[] = [];
          let plus55Values: number[] = [];

          if (absolutoChanges.length > 0) {
            absolutoLabels.push(dateToUse.toLocaleDateString());
            absolutoValues.push(absolutoChanges[0].previousRank);
            for (let index = 0; index < absolutoChanges.length; index++) {
              absolutoLabels.push(absolutoChangesDates[index].toLocaleDateString());
              absolutoValues.push(absolutoRankings[index]);
            }
          }

          if (plus45Changes.length > 0) {
            plus45Labels.push(dateToUse.toLocaleDateString())
            for (let index = 0; index < plus45Changes.length; index++) {
              plus45Labels.push(plus45ChangesDates[index].toLocaleDateString());
              plus45Values.push(plus45Rankings[index]);
            }
          }

          if (plus55Changes.length > 0) {
            plus55Labels.push(dateToUse.toLocaleDateString())
            for (let index = 0; index < plus55Changes.length; index++) {
              plus55Labels.push(plus55ChangesDates[index].toLocaleDateString());
              plus55Values.push(plus55Rankings[index]);
            }
          }
          setAbsoluteLabels(absolutoLabels);
          setAbsoluteDataset(absolutoValues);
          setPlus45Labels(plus45Labels);
          setPlus45Dataset(plus45Values);
          setPlus55Labels(plus55Labels);
          setPlus55Dataset(plus55Values);
        }
      } catch (err) {
        setError('Error al obtener los cambios de ranking');
      } finally {
        setLoading(false);
      }
    };

    fetchRankingChanges();
  }, [id]);

  if (loading) return <p>Cargando gráfico...</p>;
  if (error) return <p>{error}</p>;
  if (absoluteDataset.length === 0 && plus45Dataset.length === 0 && plus55Dataset.length === 0) return <></>;

  return (
    <div className="player-chart-rankings-container">
      <h2 className="heading-secondary">Ranking en los últimos 6 meses</h2>
      {/* //Render charts only if there is data */}
      {absoluteDataset.length > 1 &&
        <div className="chart-container">
          <LineChart
            width={isMobile ? 360 : 600}
            height={isMobile ? 300 : 380}
            series={[
              { data: absoluteDataset, label: 'Absoluto' }
            ]}
            xAxis={[{
              scaleType: 'point',
              data: absoluteLabels,
              label: 'Fecha',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17 },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15 },
              tickSize: 6,
            }]}
            yAxis={[{
              scaleType: 'linear',
              position: 'left',
              tickSize: 6,
              tickNumber: 4,
              tickMinStep: 1,
              label: 'Ranking',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17 },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              reverse: true
            }]}
          />
        </div>
      }

      {plus45Dataset.length > 1 &&
        <div className="chart-container">
          <LineChart
            width={isMobile ? 360 : 600}
            height={isMobile ? 300 : 380}
            series={[
              { data: plus45Dataset, label: '+45' }
            ]}
            xAxis={[{
              scaleType: 'point',
              data: plus45Labels,
              label: 'Fecha',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17  },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              tickSize: 6,
            }]}
            yAxis={[{
              scaleType: 'linear',
              position: 'left',
              tickSize: 6,
              tickNumber: 4,
              tickMinStep: 1,
              label: 'Ranking',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17  },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              reverse: true
            }]}
          />
        </div>
      }

      {plus55Dataset.length > 1 &&
        <div className="chart-container">
          <LineChart
            width={isMobile ? 360 : 600}
            height={isMobile ? 300 : 380}
            series={[
              { data: plus55Dataset, label: '+55' }
            ]}
            xAxis={[{
              scaleType: 'point',
              data: plus55Labels,
              label: 'Fecha',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17  },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              tickSize: 6,
            }]}
            yAxis={[{
              scaleType: 'linear',
              position: 'left',
              tickSize: 6,
              tickNumber: 4,
              tickMinStep: 1,
              label: 'Ranking',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17  },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              reverse: true
            }]}
          />
        </div>
      }
    </div>
  );
};

export default RankingChart;
