import axios from 'axios';
import { Challenge, ChallengeStatus } from '../types/Challenge';
import { ChallengeView } from '../types/ChallengeView';

// Base URL for API requests
const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/challenges`;

// Fetch all challenges
export const getAllChallenges = async (): Promise<Challenge[]> => {
  try {
    const response = await axios.get<Challenge[]>(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

// Fetch a challenge by ID
export const getChallengeById = async (id: number): Promise<Challenge> => {
  try {
    const response = await axios.get<Challenge>(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching challenge with ID ${id}:`, error);
    throw error;
  }
};

// Fetch a challenge view by ID
export const getChallengeViewById = async (id: number): Promise<ChallengeView> => {
  try {
    const response = await axios.get<ChallengeView>(`${API_BASE_URL}/views/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching challenge view with ID ${id}:`, error);
    throw error;
  }
};

// Create a new challenge
export const createChallenge = async (challenge: Partial<Challenge>): Promise<Challenge> => {
  try {
    const response = await axios.post<Challenge>(API_BASE_URL, challenge);
    return response.data;
  } catch (error) {
    console.error('Error creating challenge:', error);
    throw error;
  }
};

// Update an existing challenge status
export const updateChallengeStatus = async (id: number, challenge: Partial<Challenge>): Promise<Challenge> => {
  try {
    const response = await axios.put<Challenge>(`${API_BASE_URL}/status/${id}`, challenge);
    return response.data;
  } catch (error) {
    console.error(`Error updating challenge with ID ${id}:`, error);
    throw error;
  }
};

//Update an existing challenge proposed date
export const updateChallengeProposedDate = async (id: number, proposedDate: Date): Promise<Challenge> => {
  try {
    const response = await axios.put<Challenge>(`${API_BASE_URL}/proposedDate/${id}`, { proposedDate });
    return response.data;
  } catch (error) {
    console.error(`Error updating challenge proposed date with ID ${id}:`, error);
    throw error;
  }
};

// Delete a challenge
export const deleteChallenge = async (id: number): Promise<void> => {
  try {
    await axios.delete(`${API_BASE_URL}/${id}`);
  } catch (error) {
    console.error(`Error deleting challenge with ID ${id}:`, error);
    throw error;
  }
};

// Fetch challenges by status
export const fetchChallengesByStatus = async (
  status: ChallengeStatus,
  from?: string,
  to?: string,
  playerId?: number,
  orderDirection?: string
): Promise<ChallengeView[]> => {
  try {
    // Construct the query string with optional parameters
    const queryParams = new URLSearchParams({ status });

    if (from) {
      queryParams.append('from', from);
    }
    if (to) {
      queryParams.append('to', to);
    }
    if (playerId !== undefined) {
      queryParams.append('playerId', playerId.toString());
    }
    if (orderDirection) {
      queryParams.append('orderDirection', orderDirection);
    }

    // Make the API request with the constructed query string
    const response = await axios.get<ChallengeView[]>(`${API_BASE_URL}/views?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching challenges with status ${status}:`, error);
    throw error;
  }
};

// Accept a challenge
export const acceptChallenge = async (challengeId: number): Promise<void> => {
  try {
    await axios.post(`${API_BASE_URL}/accept/${challengeId}`);
  } catch (error) {
    console.error(`Error accepting challenge with ID ${challengeId}:`, error);
    throw error;
  }
};

// Reject a challenge
export const rejectChallenge = async (challengeId: number): Promise<void> => {
  try {
    await axios.post(`${API_BASE_URL}/reject/${challengeId}`);
  } catch (error) {
    console.error(`Error accepting challenge with ID ${challengeId}:`, error);
    throw error;
  }
};


// Reject a challenge
export const cancelChallenge = async (challengeId: number): Promise<void> => {
  try {
    await axios.post(`${API_BASE_URL}/cancel/${challengeId}`);
  } catch (error) {
    console.error(`Error accepting challenge with ID ${challengeId}:`, error);
    throw error;
  }
};

// Check if a player has pending challenges
export const checkPendingChallengesWithAction = async (playerId: number): Promise<boolean> => {
  try {
    const response = await axios.get<boolean>(`${API_BASE_URL}/hasPendingChallenges/${playerId}`);
    return response.data;
  } catch (error) {
    console.error(`Error checking pending challenges for player with ID ${playerId}:`, error);
    throw error;
  }
};

export const getChallengeCountByStatus = async (status: ChallengeStatus): Promise<number> => {
  try {
    const response = await axios.get<number>(`${API_BASE_URL}/count/${status}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching challenge count for status ${status}:`, error);
    throw error;
  }
}