import axios from 'axios';
import { OpenChallenge } from '../types/OpenChallenge';
import { RankType } from '../types/RankType';

// Base URL para las solicitudes de API
const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/open-challenges`;

// Obtener todos los desafíos abiertos
export const getOpenChallenges = async (playerId: number, rankingType: RankType): Promise<OpenChallenge[]> => {
  try {
    const response = await axios.get<OpenChallenge[]>(`${API_BASE_URL}/player/${playerId}?rankingType=${rankingType}`);
    const openChallenges = response.data;

    /* get only timeSlots starting from the next hour from now, if none, don't show the challenge */
    const now = new Date();
    const nextHour = new Date(now);
    nextHour.setHours(now.getHours() + 1);

    return openChallenges.filter(challenge => {
      // IF the proposed date is today, the time should be after the next hour. At least one of the startTimes should be after the next hour
      const challengeHasTimesAfterNextHour = challenge.startTimes.some(time => {
        const dateOfTheTimeSlot = new Date(`${challenge.proposedDate}T${time}`);
        return dateOfTheTimeSlot > nextHour;
      }
      );
      return new Date(challenge.proposedDate) > now || challengeHasTimesAfterNextHour;
    });

  } catch (error) {
    console.error('Error al obtener desafíos abiertos:', error);
    throw error;
  }
};

// Obtener un desafío abierto por ID
export const getOpenChallengeById = async (id: number): Promise<OpenChallenge> => {
  try {
    const response = await axios.get<OpenChallenge>(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener desafío abierto con ID ${id}:`, error);
    throw error;
  }
};

// Crear un nuevo desafío abierto
export const createOpenChallenge = async (openChallenge: Partial<OpenChallenge>): Promise<OpenChallenge> => {
  try {
    const response = await axios.post<OpenChallenge>(API_BASE_URL, openChallenge);
    return response.data;
  } catch (error) {
    console.error('Error al crear un desafío abierto:', error);
    throw error;
  }
};

// Cerrar un desafío abierto
export const closeOpenChallenge = async (id: number, playerId: number): Promise<OpenChallenge> => {
  try {
    const response = await axios.post<OpenChallenge>(`${API_BASE_URL}/${id}/close`, { playerId });
    return response.data;
  } catch (error) {
    console.error(`Error al cerrar el desafío abierto con ID ${id}:`, error);
    throw error;
  }
};

// Aceptar un desafío abierto
export const acceptOpenChallenge = async (challengeId: number, selectedTimeSlot: string, playerId: number): Promise<OpenChallenge> => {
  try {
    const newChallenge = await axios.post(`${API_BASE_URL}/${challengeId}/accept`, { playerId, selectedTimeSlot });
    return newChallenge.data;
  } catch (error) {
    console.error(`Error al aceptar el desafío abierto con ID ${challengeId}:`, error);
    throw error;
  }
};