import axios from 'axios';
import { RankType } from '../types/RankType';
import { BaseRankingView, OrdinalRankingView } from '../types/BaseRankingView';
import { RankingResponseModel } from '../types/RankingResponseModel';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/rankings`;

// Fetch all rankings
export const getAllRankings = async (): Promise<RankingResponseModel<BaseRankingView>> => {
  const response = await axios.get<RankingResponseModel<BaseRankingView>>(`${API_BASE_URL}/all`);
  return response.data;
};

// Fetch a ranking by player ID
export const getRankingByPlayerId = async (playerId: number): Promise<BaseRankingView | undefined> => {
  const response = await axios.get<RankingResponseModel<BaseRankingView>>(`${API_BASE_URL}/player/${playerId}`);
  // Extract the ranking from the response
  return response.data.rankings[0]; // Since the response contains an array, return the first element
};

// Fetch ordinal rankings with optional filtering by rank type
export const getOrdinalRankings = async (start: number, end: number, rankType?: RankType, playerId?: number): Promise<RankingResponseModel<OrdinalRankingView>> => {
  const response = await axios.get<RankingResponseModel<OrdinalRankingView>>(`${API_BASE_URL}/ordinal`, {
    params: { start, end, rankType, playerId },
  });
  return response.data;
};

// Fetch dynamic rankings with min and max level filtering
export const getDynamicRankings = async (minLevel: number, maxLevel: number, playerId?: number): Promise<RankingResponseModel<BaseRankingView>> => {
  const response = await axios.get<RankingResponseModel<BaseRankingView>>(`${API_BASE_URL}/dynamic`, {
    params: { minLevel, maxLevel, playerId },
  });
  return response.data;
};
