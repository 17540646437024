import React, { useState } from 'react';
import './CancelChallengeButton.css';

interface CancelChallengeButtonProps {
  challengeId: number;
  onCancel: (challengeId: number) => void;
}

const CancelChallengeButton: React.FC<CancelChallengeButtonProps> = ({ challengeId, onCancel }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleCancelChallenge = (event: React.MouseEvent) => {
    event.stopPropagation();

    const confirmCancel = window.confirm('¿Seguro que deseas cancelar el desafío?');
    if (confirmCancel) {
      setIsDisabled(true);
      onCancel(challengeId);
    }
  };

  return (
    <button
      onClick={handleCancelChallenge}
      className="cancel-challenge-button"
      disabled={isDisabled}
    >
      Cancelar Desafío
    </button>
  );
};

export default CancelChallengeButton;